import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FFlagsContextProviderService, FFlagsService } from "@bancolombia/ngx-fflags";
import { BcDialogRef, BcDialogService } from '@bancolombia/design-system-web/bc-services';
import { BcAlertComponent } from "@bancolombia/design-system-web/bc-alert";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";


import { FormatDatePipe } from "../../shared/pipes/format/format-date.pipe";
import { dataOption } from "../../shared/models/dataNameHeaders";
import { ObligationsService } from "../../query/services/obligations.service";
import { variables, codeErrors } from "../../shared/constants";
import { numberRepeatLines } from "../../shared/constants";
import { PopupInfoComponent } from "../popup-info/popup-info.component";
import { PaymentsService } from "../../payments/services/payments.service";
import { SetDataApp } from '../../store/actions/shareparam.action';
import { BcTableActionButton } from "@bancolombia/design-system-behaviors";
import { BcTableContainerComponent } from "@bancolombia/design-system-web/bc-table";
import { BcModalComponent } from "@bancolombia/design-system-web/bc-modal";






const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-detailproyect",
  templateUrl: "./detailproyect.component.html",
  styleUrls: ["./detailproyect.component.css"],
  providers: [FormatDatePipe],
})
export class DetailproyectComponent implements OnInit {
  @Input() dataDetail: any;
  @Output() backScreen = new EventEmitter();
  @ViewChild(BcTableContainerComponent, { static: false }) tableContainer: BcTableContainerComponent;
  @ViewChild(BcModalComponent, { static: false }) bcModalComponent?: BcModalComponent;

  numberRepeatLines = numberRepeatLines;
  listCredit: any;
  idProject: any;
  nameProject: any;
  amountProject: any;
  dueDate: any;
  nextPayProject: any;
  amountPay: any;
  totalBalance: any;
  currentInterestBalance: any;
  arrearsInterestBalance: any;
  capitalBalance: any;
  queryObligationCompLoading = true;
  queryObligationComp = false;
  configTable = false;
  icono = true;
  pageNum = 1;
  recordsNum = variables.code10;
  responseArray: any[] = [];
  temporaryObligations: any[] = [];
  respPaginator: any;
  element: any;
  btnModal: any;
  prop: any;
  ArrowL: any;
  ArrowR: any;
  selectItemsTable = new Array();
  itemSelected = new Array();
  selectResponseData: any;
  arrowLeft = true;
  arrowRight = true;
  dataOptions: any;
  initialNumRecordspag: any;
  NumRecordspag: any;
  errorInput: any;
  cont = 0;
  showImg = true;
  shownoproducts = true;
  enableClic = true;
  enableClic2 = false;
  errorMsg = false;
  btnDownAvailable = false;
  idObligation: any;
  propContainer: any;
  movementsBtnWindow: any;
  movements = false;
  projectDataMovements = new Array();
  generalInfoData: any;
  pagerMoveOblig = true;
  creditProject = true;
  documents: any;
  propObligationCont: any;
  propMovementsCont: any;
  creditSelect: any;
  ejemplo: any;
  dataDropDownList: any;
  morePager: any;
  arrayDownloadFileObligations: any[] = [];
  arrayNumPager: any[] = [];
  showHidden = false;
  modalInferior: any;
  isTabs = true;
  isBtnApply = true;
  isBtnConfig = false;
  isModal = false;
  isModalInferior = false;
  msgTitle: string = '';
  visiblePaginator: boolean = false;
  pagNumber: number = 1;
  sizePagNum: boolean = true;

  mostrarBotonPagarFFlag: boolean = false;
  mostrarAlertaPagoAnterior: boolean = true;
  dialogRef: BcDialogRef;
  InlineAlertStatus = '';
  isDismissed: boolean;
  isClosed: boolean;
  mostrarModal: boolean = false;
  detalleModal: any;
  showBtnDescargarObligaciones: boolean = false;
  errorMsgGeneral: boolean = false;
  mostrarAlertaMoraObligacion: boolean = false;
  @Output() irInicioErrorGeneral = new EventEmitter<boolean>();


  dropdownOptions: BcTableActionButton[] = [
    { id: 'btnOptionDetalle', text: 'Detalle de la obligación', icon: 'view' },
    { id: 'btnPagarObligacion', text: 'Pagar obligación', icon: 'send-money' }
  ]

  dropdownOptionsDetailOperation: BcTableActionButton[] = [
    { id: 'btnOptionDetalle', text: 'Detalle de la obligación', icon: 'view' },
  ]



  constructor(

    private dateFormat: FormatDatePipe,
    private store: Store<{ shareparams: any }>,
    public obligationService: ObligationsService,
    public dialog: MatDialog,
    private router: Router,
    private paymentService: PaymentsService,
    private contextProvider: FFlagsContextProviderService,
    private fflags: FFlagsService,
    private dialogService: BcDialogService,
  ) {
  }

  ngOnInit() {
    this.getState();
    this.showDataInfoGeneral(this.dataDetail.projectId);
    // this.optTableConfig();
    this.getObligations();
  }

  public backCreditScreen() {
    this.backScreen.emit(1);
  }

  public async getInfoFFlags() {
    const featureWithDash = 'mostrarBotonPagar';
    const areFeaturesEnabled = await this.fflags.areFeaturesEnabled([featureWithDash])
    this.mostrarBotonPagarFFlag = areFeaturesEnabled[featureWithDash].enabled;
  }

  public getState() {
    this.store.select('shareparams').subscribe(async state => {
      if (state) {
        const context = this.contextProvider.getContext();
        context.properties['nit'] = state.dataapp.companyId;
        this.contextProvider.setContext(context);

        await this.getInfoFFlags();
      }
    })
  }

  public showDataInfoGeneral(idProjectCredit: any) {

    // TODO: Descomentar lo del segundo pago

    this.paymentService.validateSecondPayment(idProjectCredit).subscribe((response: any) => {
      this.dialogRef?.close(null);
      if (!response.data.attributes.result.canBePaid) {
        this.mostrarAlertaPagoAnterior = false;
        this.openAlertPayment();
      } else {
        this.mostrarAlertaPagoAnterior = true;
      }
    })

    this.idObligation = idProjectCredit;
    this.dataDropDownList = this.dataDetail.dataSelected.accountDetails;
    this.dataDropDownList.forEach((element: any) => {
      if (element.projectId == idProjectCredit) {
        this.nameProject = element.projectName;
        this.idProject = element.projectId;
        this.amountProject = element.approvedAmount;
        this.totalBalance = element.pendingBalance.amount;
        this.amountPay = element.quarterlyInterest.amount;
        this.dueDate = element.dueDate;
        this.nextPayProject = element.nextPaymentDate;
        this.currentInterestBalance = element.currentInterestBalance;
        this.arrearsInterestBalance = element.arrearsInterestBalance;
        this.capitalBalance = element.capitalBalance;

        this.generalInfoData = [
          {
            ValorAprobado: this.amountProject,
            SaldoTotal: this.totalBalance,
            ValorAPagarCuota: this.amountPay,
            SaldoInteresesCorrientes: this.currentInterestBalance,
            SaldoMora: this.arrearsInterestBalance,
            SaldoCapital: this.capitalBalance,
            FechaOportunaDePago: this.nextPayProject,
            FechaDeVencimiento: this.dueDate,
          },
        ];
      }
    });



  }


  public changeDataSelectedInfoGeneral(eventId) {
    this.mostrarAlertaPagoAnterior = true;
    this.nameProject = "";
    this.idProject = "";
    this.amountProject = "";
    this.totalBalance = "";
    this.amountPay = "";
    this.dueDate = "";
    this.nextPayProject = "";
    this.initialNumRecordspag = null;
    this.NumRecordspag = null;
    this.pageNum = 1;
    this.arrowLeft = true;
    this.arrowRight = true;
    this.responseArray = [];
    this.configTable = false;
    this.queryObligationCompLoading = true;
    this.movements = false;
    this.cont = 0;
    this.showImg = true;
    this.shownoproducts = true;
    this.enableClic = true;
    this.enableClic2 = false;
    this.errorMsg = false;
    this.btnDownAvailable = false;
    this.arrayDownloadFileObligations = [];
    this.arrayNumPager = [];
    this.showDataInfoGeneral(eventId);
    this.getObligations();
  }

  public showConfigTable() {
    if (this.isModal == false) {
      this.isModal = true; // show
      this.btnModal.disabled = true;
      this.isModalInferior = false;
      this.isBtnConfig = false;
      this.btnStatus();
    } else {
      this.isModal = false;
      this.btnModal.disabled = false;
    }
  }



  public btnStatus() {
    if (this.isModalInferior === true || this.isModal === true) {
      if (
        this.selectItemsTable.length == 0 ||
        this.selectItemsTable.length < variables.code4 ||
        this.selectItemsTable.length > variables.code7
      ) {
        this.isBtnApply = false;
        this.icono = false;
      } else {
        this.isBtnApply = true;
        this.icono = true;
      }
    }
  }


  public statusbtnArrows(respPag: any) {
    if (respPag.data.attributes.param.paginations.remainingRecords == "Y") {
      if (respPag.data.attributes.param.paginations.pageNumber == "1") {
        this.initialNumRecordspag = parseInt(respPag.data.attributes.param.paginations.pageNumber);
        this.NumRecordspag = respPag.data.attributes.param.obligationsDetail.length;
      }
    }
    this.morePager = respPag.data.attributes.param.paginations.remainingRecords;
  }

  // public selectItems(event: any, item: any) {
  //   if (event.target.checked) {
  //     this.dataOptionsValidate(event, item);
  //     this.selectItemsTable.push(item);
  //     this.btnStatus();
  //   } else {
  //     this.dataOptionsValidate(event, item);
  //     const posArray = this.selectItemsTable.indexOf(item);
  //     this.selectItemsTable.splice(posArray, 1);
  //     this.btnStatus();
  //   }
  // }
  // public dataOptionsValidate(event: any, item: any) {
  //   this.dataOptions.forEach((element: any) => {
  //     if (element === item) {
  //       item.check = event.target.checked;
  //     }
  //   });
  // }

  // public applyChangeConfigTable() {
  //   this.itemSelected = [...this.selectItemsTable];
  //   if (this.isModal == true) {
  //     this.showConfigTable();
  //   } else if (this.isModalInferior == true) {
  //     this.newPositionModal();
  //   }
  // }

  public relodQueryObligations() {
    this.queryObligationCompLoading = true;
    this.errorMsg = false;
    this.btnDownAvailable = false;
    this.configTable = false;
    this.getObligations();
  }



  public getObligations() {
    this.store
      .select("shareparams")
      .subscribe((state: any) => {
        this.obligationService
          .pcsQueryObligationsRq(
            state.dataapp,
            this.idObligation,
            this.pageNum,
            this.recordsNum,
            this.dataDetail.dataSelected.idCard,
            this.dataDetail.dataSelected.typeDocCard
          )
          .subscribe(
            (response: any) => {
              const pageNum = response.data.attributes.param.paginations.pageNumber;
              this.pagNumber = pageNum;
              this.sizePagNum = this.pagNumber > 9 ? false : true;
              if (this.responseArray.length > 0) {
                this.temporaryObligations = [];
                this.temporaryObligations = response.data.attributes.param.obligationsDetail;
                this.temporaryObligations = this.responseArray.concat(this.temporaryObligations);
                this.obligationService.saveObligaciones(this.temporaryObligations);
                this.responseArray = response.data.attributes.param.obligationsDetail;
              } else {
                this.responseArray = response.data.attributes.param.obligationsDetail;
                this.obligationService.saveObligaciones(this.responseArray);
              }
              this.formatFileResp();
              this.validateAddResponseExcelFile(pageNum);
              this.queryObligationCompLoading = false;
              this.statusbtnArrows(response);
              this.validarMostarAlertaSiEstaEnMora(response.data.attributes.timestamp, this.nextPayProject)
            },
            (error: any) => {
              this.visiblePaginator = false;
              if (this.cont == variables.code4) {
                this.queryObligationCompLoading = false;
                this.errorMsg = false;
                this.errorMsgGeneral = true;
                // const actionerror = new SetDataApp(error);
                // this.store.dispatch(actionerror);
                // this.router.navigate(['notificacion']);
              } else {
                const getError = error.error?.errors[0];
                this.msgTitle = getError?.code === 3 && getError?.status === codeErrors.code406
                  ? '' : 'Algo salió mal';
                this.btnDownAvailable = false;
                this.validateError(error);
              }
            }
          );
      })
      .unsubscribe();
  }

  public validarMostarAlertaSiEstaEnMora(timeStampRespOblig, nextPayProject) {
    const formatTimeStamp = timeStampRespOblig.substring(0, 8);
    this.mostrarAlertaMoraObligacion = (nextPayProject >= formatTimeStamp) ? false : true;
  }

  public formatFileResp() {
    this.responseArray.forEach((file) => {
      file.nextPaymentDate = this.dateFormat.transform(file.nextPaymentDate);
      file.dueDate = this.dateFormat.transform(file.dueDate);
      file.negotiatedDisbursementRate = file.negotiatedDisbursementRate + " %";
      file.disbursementDate = this.dateFormat.transform(file.disbursementDate);
    });
  }

  public validateAddResponseExcelFile(currentPage: any) {
    const fileFinded = this.arrayNumPager.some((page) => page === currentPage);
    if (!fileFinded) {
      this.arrayNumPager.push(currentPage);
      this.arrayDownloadFileObligations = this.arrayDownloadFileObligations.concat(this.responseArray);
    }
  }


  public validateError(errorResp: any) {
    if (errorResp.error.errors[0].status !== codeErrors.code406) {
      this.cont = this.cont + 1;
      if (this.cont == variables.code4) {
        this.errorInput = errorResp.error.errors[0].detail;
        this.relodQueryObligations();
      } else {
        this.queryObligationCompLoading = false;
        this.errorMsg = true;
        this.errorInput = errorResp.error.errors[0].detail;
      }
    } else {
      this.errorMsg = true;
      this.showImg = false;
      this.enableClic = false;
      this.enableClic2 = false;
      this.queryObligationCompLoading = false;
      this.errorInput = errorResp.error.errors[0].detail;
    }
  }

  public openPopup() {
    if (this.morePager == "Y") {
      const dialogRef = this.dialog.open(PopupInfoComponent, {
        data: { dataKey: "obligation" },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(() => {
        this.showHidden = true;
        setTimeout(() => {
          this.showHidden = false;
        }, 3000);
      });
    }
  }

  public exportAsXLSX(): void {
    this.openPopup();
    var json: any[] = this.arrayDownloadFileObligations;
    const excelFileName = this.nameProject + "_" + this.idProject;
    const data: any = JSON.parse(JSON.stringify(json));
    const infogeneral: any = JSON.parse(JSON.stringify(this.generalInfoData));
    const newData = this.deleteDataInfo(data);
    infogeneral.forEach((item) => {
      item.ValorAprobado = parseFloat(item.ValorAprobado);
      item.SaldoTotal = parseFloat(item.SaldoTotal);
      item.ValorAPagarCuota = parseFloat(item.ValorAPagarCuota);
      item.SaldoInteresesCorrientes = parseFloat(item.SaldoInteresesCorrientes);
      item.SaldoMora = parseFloat(item.SaldoMora);
      item.SaldoCapital = parseFloat(item.SaldoCapital);
      item.FechaOportunaDePago = this.dateFormat.transform(
        item.FechaOportunaDePago
      );
      item.FechaDeVencimiento = this.dateFormat.transform(
        item.FechaDeVencimiento
      );
    });
    this.addDataExcelDownload(excelFileName, newData, infogeneral);
  }

  public deleteDataInfo(data: any) {
    data.forEach((element) => {
      element.NumeroDeCredito = element.obligationId;
      element.DescripcionDelProducto = element.productDescription;
      element.ValorDesembolsado = parseFloat(element.copDisbursementAmount);
      element.SaldoTotal = parseFloat(element.copTotalBalance);
      element.SaldoCapital = parseFloat(element.copTotalCapital);
      element.FechaDePago = element.nextPaymentDate;
      element.FechaVencimiento = element.dueDate;
      element.TasaDesembolsosEA = parseFloat(
        element.negotiatedDisbursementRate
      );
      element.FechaDeDesembolso = element.disbursementDate;
      element.SaldoInteresesCorrientes = parseFloat(
        element.copTotalCurrentInterest
      );
      element.SaldoInteresesMora = parseFloat(element.copArrearTotalInterest);
      delete element.creditStatus;
      delete element.copTotalExpiredCapital;
      delete element.copExpiredCurrentInterest;
      delete element.copArrearExpiredInterest;
      delete element.copSuspendedInterest;
      delete element.copTotalExpiredBalance;
      delete element.obligationId;
      delete element.productDescription;
      delete element.copDisbursementAmount;
      delete element.copTotalBalance;
      delete element.copTotalCapital;
      delete element.nextPaymentDate;
      delete element.dueDate;
      delete element.negotiatedDisbursementRate;
      delete element.disbursementDate;
      delete element.copTotalCurrentInterest;
      delete element.copArrearTotalInterest;
    });
    return data;
  }


  private async addDataExcelDownload(nameExel: string, dataObligations: any[], generalInfo: any[]) {
    const workbook = new ExcelJS.Workbook();
    const worksheetIg = workbook.addWorksheet("Informacion general");
    const worksheet = workbook.addWorksheet("Informacion por obligacion");

    // Escribir títulos y datos en "Informacion general"
    const generalInfoColumns = Object.keys(generalInfo[0]).map(key => ({ key, header: key, width: 15 }));
    worksheetIg.columns = generalInfoColumns;
    worksheetIg.addRows(generalInfo);

    // Aplicar formato a las celdas de "Informacion general"
    worksheetIg.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        for (let i = 1; i <= 6; i++) {
          row.getCell(i).numFmt = "$#,##0.00";
        }
      }
    });

    // Escribir títulos y datos en "Informacion por obligacion"
    const dataObligationsColumns = Object.keys(dataObligations[0]).map(key => ({ key, header: key, width: 15 }));
    worksheet.columns = dataObligationsColumns;
    worksheet.addRows(dataObligations);

    // Aplicar formato a las celdas de "Informacion por obligacion"
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        [3, 4, 5, 10, 11].forEach(col => {
          row.getCell(col).numFmt = "$#,##0.00";
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, nameExel);
  }



  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    let currentDay = new Date().getDate().toString();
    if (Number(currentDay) < variables.code10) {
      currentDay = "0" + currentDay;
    }
    let currentMounth = (new Date().getMonth() + 1).toString();
    if (Number(currentMounth) < variables.code10) {
      currentMounth = "0" + currentMounth;
    }
    const currentYear = new Date().getFullYear().toString();
    let currentHr = new Date().getHours().toString();
    if (Number(currentHr) < variables.code10) {
      currentHr = "0" + currentHr;
    }
    let currentMins = new Date().getMinutes().toString();
    if (Number(currentMins) < variables.code10) {
      currentMins = "0" + currentMins;
    }
    let currentSeg = new Date().getSeconds().toString();
    if (Number(currentSeg) < variables.code10) {
      currentSeg = "0" + currentSeg;
    }
    FileSaver.saveAs(
      data,
      fileName +
      "_" +
      currentDay +
      currentMounth +
      currentYear +
      "_" +
      currentHr +
      currentMins +
      currentSeg +
      EXCEL_EXTENSION
    );
  }

  public toPaySendData() {
    this.router.navigateByUrl(`pagos`);
    this.paymentService.saveData(this.idObligation, this.dataDropDownList);
  }

  openAlertPayment(): void {
    this.dialogRef = this.dialogService.open(BcAlertComponent, {
      id: 'MyAlertId',
      type: 'warning',
      title: 'Realizaste un pago recientemente',
      text: 'Para volver a realizar un pago de este proyecto debes esperar 10 minutos.',
      timeout: 600000,
      elementRef: 'IdElementRef',
      insertBefore: false
    });

  }

  // Nuevo

  changeTab(opcionSeleccionada: number) {

    switch (opcionSeleccionada) {
      case 0:
        this.movements = false;
        this.showBtnDescargarObligaciones = false;
        break;
      case 1:
        this.movements = true;
        this.showBtnDescargarObligaciones = true;
        this.projectDataMovements = [
          {
            ProjectId: this.idProject,
            ProjectName: this.nameProject,
            documentNum: this.dataDetail.dataSelected.idCard,
            documentType: this.dataDetail.dataSelected.typeDocCard,
          },
          this.generalInfoData,
        ];
        this.errorMsg = false;
        break;
    }
  }

  optionSelected(data: any) {
    switch (data.optionSeleted) {
      case 'btnOptionDetalle':
        this.detalleModal = data.rowData;
        this.mostrarModal = true;
        this.abrirModalDetalleOperacion();
        break;
      case 'btnPagarObligacion':
        this.obligationService.guardarObligacion(data.rowData, this.nameProject, this.idProject);
        this.router.navigateByUrl('pagos/operaciones');
        break;
    }

  }

  onChangePage(data: any) {
    const { NextPage, PreviousPage } = data;
    if (NextPage && !PreviousPage) {
      this.next();
    }
    if (PreviousPage && !NextPage) {
      this.back();
    }
  }

  public back() {
    this.initialNumRecordspag = this.initialNumRecordspag - variables.code10;
    this.NumRecordspag = this.NumRecordspag - variables.code10;
    this.pageNum = this.pageNum - 1;
    this.queryObligationCompLoading = true;
    this.getObligations();
  }

  public next() {
    if (this.morePager === 'Y') {
      this.initialNumRecordspag = this.initialNumRecordspag + variables.code10;
      this.NumRecordspag = this.NumRecordspag + variables.code10;
      this.pageNum = this.pageNum + 1;
      this.queryObligationCompLoading = true;
      this.getObligations();
    }
  }



  cerrarModal(event: any) {
    // Si le dan al boton pagarOperacion desde la modal
    if (event === 0) {
      if (!this.mostrarAlertaMoraObligacion) {
        this.obligationService.guardarObligacion(this.detalleModal, this.nameProject, this.idProject);
        this.router.navigateByUrl('pagos/operaciones');
        this.bcModalComponent?.shutDown();
      }
    }
    this.bcModalComponent?.shutDown();
  }

  abrirModalDetalleOperacion() {
    setTimeout(() => {
      this.bcModalComponent.openModal();
    }, 10);
  }

  irInicioErrorGeneralObligaciones() {
    this.irInicioErrorGeneral.emit(true);
  }


}
