import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { dataItems } from '../../shared/models/optionsSelectMovements';
import { FormatDatePipe } from '../../shared/pipes/format/format-date.pipe';
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { MovementsService } from '../../query/services/movements.service';
import { environment } from '../../../environments/environment';
import { codeErrors, numberRepeatLines, variables } from '../../shared/constants'
import { DataMovementsSce } from '../../shared/models/dataQueryMovements';
import { MatDialog } from '@angular/material/dialog';
import { PopupInfoComponent } from '../popup-info/popup-info.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { PaymentsService } from '../../payments/services/payments.service';
import { SetDataApp } from '../../store/actions/shareparam.action';
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss'],
  providers: [ FormatDatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MovementsComponent implements OnInit {

  numberRepeatLines = numberRepeatLines;
  pageNum = variables.code1;
  recordsNum = variables.code10;
  startDate : any  = '';
  endDate : any  = '';
  surrogacyFilter = 'N';
  responseMovements: any[] = [];
  itemsSelectedArray = new Array();
  SelectedItems = new Array();
  elementsCheck: any;
  elementFilterDate: any;
  propBtnFind: any;
  element: any;
  prop: any;
  buttonModal: any;
  optionSelect = dataItems;
  LeftArrow = true;
  RightArrow = true;
  LArrow: any;
  RArrow: any;
  initialNumPag : any;
  Recordspag: any;
  icono = true;
  queryMovementsCompLoading = true;
  showConfigurationTable = false;
  errorMessageMovements = false;
  noProducts = true;
  clicReload = true;
  clicLater = false;
  contError = variables.code0;
  inputError;
  Img = true;
  tittle = true;
  availableBtndown = false;
  minDate = new Date();
  maxDate = new Date();
  calendarFilter = true;
  objQueryMovements: DataMovementsSce = new DataMovementsSce();
  arrayDownloadFile: any[] = [];
  arrayNumbersPager: any[] = [];
  moreDataPager: any;
  showHidden = false;
  isInferiorModal = false;
  isBtnApply = true;
  isSuperiorModal = false;
  isBtnConfig = false;
  msgTitle: string = '';
  pagNumber : number = 1;
  sizePagNum : boolean = true;
  visiblePaginator : boolean = false;
  @Input() projectData: any;
  @Output() obligationBackScreen = new EventEmitter;
  @Input() dataProjects: any;
  constructor(
    private dateFormat: FormatDatePipe,
    private store: Store<{ shareparams: any }>,
    public serviceMovements: MovementsService,
    public dialog: MatDialog,
    private router: Router,
    private paymentService: PaymentsService) {}

  ngOnInit() {
    this.setDate();
    this.tableConfig();
    this.getMovements();
  }

  public setObjQueryMovements() {
    this.objQueryMovements.projectId = this.projectData[0].ProjectId;
    this.objQueryMovements.documentNum = this.projectData[0].documentNum;
    this.objQueryMovements.documentType = this.projectData[0].documentType;
    this.objQueryMovements.numberPag = this.pageNum;
    this.objQueryMovements.recordsNum = this.recordsNum;
    this.objQueryMovements.surrogacyFilter = this.surrogacyFilter;
    this.objQueryMovements.startDate = this.startDate;
    this.objQueryMovements.endDate = this.endDate;
  }

  public tableConfig() {
    this.prop = document.getElementById('containerArrowsModal');
    this.buttonModal = this.prop.getElementsByClassName('buttonModal')[0];
    this.elementsCheck = document.getElementById('surrugacyCheck');
    this.elementFilterDate = document.getElementById('contDatePick');
    this.propBtnFind = this.elementFilterDate.getElementsByClassName('buttonFilterDate');
    this.propBtnFind[0].disabled = true;
    this.buttonModal.disabled = true;
    this.optionSelect.forEach(e => {
      if (e.check == true) {
        this.itemsSelectedArray.push(e);
      }
    });
    this.SelectedItems = [...this.itemsSelectedArray];
    this.LArrow = this.prop.getElementsByClassName('bttn-left')[0];
    this.RArrow = this.prop.getElementsByClassName('bttn-right')[0];
    this.LArrow.disabled = true;
    this.RArrow.disabled = true;
  }

  public configTableModal() {
    if (this.isSuperiorModal === false) {
      this.isSuperiorModal = true;
      this.buttonModal.disabled = true;
      this.isInferiorModal = false;
      this.isBtnConfig = false;
        this.buttonStatusApply();
    } else {
      this.buttonModal.disabled = false;
      this.isSuperiorModal = false;
     }
  }


  public modalInferior(){
    if (this.isInferiorModal === false) { // hide
      this.isInferiorModal = true; // show
      this.isBtnConfig = true; // desabled
      this.isSuperiorModal = false;
        this.buttonStatusApply();
    } else {
      this.isInferiorModal = false;
      this.isBtnConfig = false;
      this.buttonModal.disabled = false;
    }
  }

  public setDate() {
    if (environment.ENVIROMENT === 'PDN') {
      this.maxDate = new Date();
      const currentYear = this.maxDate.getFullYear();
      const currentMonth = this.maxDate.getMonth();
      const currentDay = this.maxDate.getDate();
      this.minDate = new Date(currentYear, currentMonth - variables.code3, currentDay);
      this.startDate =  new Date(currentYear, currentMonth - variables.code1, currentDay);
    } else {
      const year = environment.YEAR;
      const month = environment.MONTH;
      const day = environment.DAY;

      this.maxDate = new Date(year, (month - 1), day);
      this.minDate = new Date(year, (month - variables.code3) - 1, day);
      this.startDate =  new Date(year, (month - variables.code2), day);
    }
    this.endDate = this.maxDate;

    this.startDate = this.formatDate(this.startDate);
    this.endDate = this.formatDate(this.endDate);
  }

  formatDate(date : Date) : string {
      let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

    return [year, month, day].join('-');
  }

  public changeStartDate(event: any) {
    if(event != null){
      this.propBtnFind[0].disabled = false;
      let initialDay = event.format('D');
      let initialMounth = (event.format('M')).toString();
      if (Number(initialDay) < variables.code10) { initialDay = '0' + initialDay; }
      if (Number(initialMounth) < variables.code10) { initialMounth = '0' + initialMounth; }
      this.startDate = event.format('YYYY-MM-DD');
      this.endDate = '';
      this.propBtnFind = this.elementFilterDate.getElementsByClassName('buttonFilterDate');
      this.propBtnFind[0].disabled = true;
      this.propBtnFind[0].cursor = 'default';
    }
  }

  public changeEndDate(event:Moment) {
    if(event != null) {
      this.propBtnFind[0].disabled = false;
      let finalDay = event.format('D');
      let finalMounth = (event.format('M')).toString();
      if (Number(finalDay) < variables.code10) {finalDay = '0' + finalDay}
      if (Number(finalMounth) < variables.code10) {finalMounth = '0' + finalMounth }
      this.endDate = event.format('YYYY-MM-DD');
    }
  }

  public filterDates() {
      if(this.startDate.length > 0 && this.endDate.length > 0){
          this.initialNumPag = '';
          this.Recordspag = '';
          this.pageNum = 1;
          this.recordsNum = variables.code10;
          this.propBtnFind[0].disabled = true;
          this.calendarFilter = true;
          this.LeftArrow = true;
          this.RightArrow = true;
          this.LArrow.disabled = true;
          this.RArrow.disabled = true;
          this.elementsCheck.disabled = true;
          this.buttonModal.disabled = true;
          this.errorMessageMovements = false;
          this.availableBtndown = false;
          this.queryMovementsCompLoading = true;
          this.showConfigurationTable = false;
          this.getMovements();
      }
  }

  public queryObligations() {
    this.isSuperiorModal = false;
    this.isInferiorModal = false;
    this.obligationBackScreen.emit(1);
  }

  public statusArrowsPager(respPag:any) {
    if (respPag.data.attributes.param.paginations.pageNumber !== null) {
      if (respPag.data.attributes.param.paginations.pageNumber == 1) {
        this.initialNumPag = parseInt(respPag.data.attributes.param.paginations.pageNumber);
        this.Recordspag = respPag.data.attributes.param.movements.length;
        this.LArrow.disabled = true;
        this.LeftArrow = true;
      } else {
        this.LeftArrow = false;
        this.LArrow.disabled = false;
      }
      if (respPag.data.attributes.param.paginations.moreData == "Y") {
        this.RArrow.disabled = false;
        this.RightArrow = false;
      } else {
        this.RArrow.disabled = true;
        this.RightArrow = true;
        this.showHidden = false;
      }
      this.moreDataPager = respPag.data.attributes.param.paginations.moreData;
    }
  }

  public buttonStatusApply() {
    if (this.isSuperiorModal === true || this.isInferiorModal === true) {
      if (
        this.itemsSelectedArray.length == 0 ||
        this.itemsSelectedArray.length < variables.code4 ||
        this.itemsSelectedArray.length > variables.code7
      ) {
        this.isBtnApply = false;
        this.icono = false;
      } else {
        this.isBtnApply = true;
        this.icono = true;
      }
    }

  }

  public requestBackPager() {
    this.initialNumPag = this.initialNumPag - variables.code10;
    this.Recordspag = this.Recordspag - variables.code10;
    this.LeftArrow = true;
    this.RightArrow = true;
    this.LArrow.disabled = true;
    this.RArrow.disabled = true;
    this.pageNum = this.pageNum - 1;
    this.showConfigurationTable = false;
    this.buttonModal.disabled = true;
    this.propBtnFind[0].disabled = true;
    this.calendarFilter = true;
    this.queryMovementsCompLoading = true;
    this.availableBtndown = false;
    this.getMovements();
  }

  public requestNextPager() {
    this.initialNumPag = this.initialNumPag + variables.code10;
    this.Recordspag = this.Recordspag + variables.code10;
    this.LeftArrow = true;
    this.RightArrow = true;
    this.LArrow.disabled = true;
    this.RArrow.disabled = true;
    this.pageNum = this.pageNum + 1;
    this.buttonModal.disabled = true;
    this.showConfigurationTable = false;
    this.propBtnFind[0].disabled = true;
    this.calendarFilter = true;
    this.queryMovementsCompLoading = true;
    this.availableBtndown = false;
    this.getMovements();
  }

  public addItemsSelected(event:any, item: any) {
    if (event.target.checked) {
      this.dataOptionsValidate(event, item);
      this.itemsSelectedArray.push(item);
      this.buttonStatusApply();
    } else {
      this.dataOptionsValidate(event, item);
      const posArray = this.itemsSelectedArray.indexOf(item);
      this.itemsSelectedArray.splice(posArray, 1);
      this.buttonStatusApply();
    }
  }
  public dataOptionsValidate(event: any, item: any) {
    this.optionSelect.forEach((element) => {
      if (element === item) {
        item.check = event.target.checked;
      }
    });
  }


  public applyChanges() {
    this.SelectedItems = [...this.itemsSelectedArray];
    if ( this.isSuperiorModal == true) {
      this.configTableModal();
    } else if (this.isInferiorModal == true ){
      this.modalInferior();
    }
  }


  public relodQueryMovements() {
    if (this.contError == variables.code4) {
      this.errorMessageMovements = true;
      this.Img = false;
      this.clicReload = false;
      this.clicLater = true;
      this.availableBtndown = false;
      this.queryMovementsCompLoading = false;
    } else {
      this.errorMessageMovements = false;
      this.queryMovementsCompLoading = true;
      this.showConfigurationTable = false;
      this.availableBtndown = false;
      this.getMovements();
    }
  }

  public filterSub() {
    this.initialNumPag = '';
    this.Recordspag = '';
    this.pageNum = 1;
    this.recordsNum = variables.code10;
    this.LeftArrow = true;
    this.RightArrow = true;
    this.LArrow.disabled = true;
    this.RArrow.disabled = true;
    this.elementsCheck.disabled = true;
    this.buttonModal.disabled = true;
    this.propBtnFind[0].disabled = true;
    this.calendarFilter = true;
    if (this.surrogacyFilter == 'N') {
      this.surrogacyFilter = 'Y';
    } else {
      this.surrogacyFilter = 'N';
    }
    this.errorMessageMovements = false;
    this.availableBtndown = false;
    this.queryMovementsCompLoading = true;
    this.showConfigurationTable = false;
    this.getMovements();
  }

  public getMovements() {
    this.setObjQueryMovements();
    this.store.select('shareparams').subscribe((state: any) => {
      this.serviceMovements.pcsQueryMovementsRq(state.dataapp, this.objQueryMovements).subscribe((resp: any) => {
        const pagerNumber = resp.data.attributes.param.paginations.pageNumber;
        this.pagNumber = pagerNumber;
        this.sizePagNum = this.pagNumber > 9 ? false : true;
        this.responseMovements = resp.data.attributes.param.movements;
        this.formatFileDate();
        this.addResponseFileExcel(pagerNumber);
        this.changeValues();
        this.statusArrowsPager(resp);
        this.visiblePaginator = true;
      }, error => {
        this.visiblePaginator= false;
        if (this.contError == variables.code3) {
          const actionerror = new SetDataApp(error);
          this.store.dispatch(actionerror);
          this.router.navigate(['notificacion']);
    } else {
          const getError =  error.error?.errors[0];
          this.msgTitle = getError?.code === 3 && getError?.status === codeErrors.code406 ? '' : 'Lo sentimos';
    }
        this.availableBtndown = false;
        this.errorValidateResp(error);
      });
    }).unsubscribe();
  }

  public formatFileDate() {
    this.responseMovements.forEach(file => {
      file.dateValue = this.dateFormat.transform(file.dateValue);
    });
  }

  public changeValues() {
    this.queryMovementsCompLoading = false;
    this.showConfigurationTable = true;
    this.buttonModal.disabled = false;
    this.elementsCheck.disabled = false;
    this.calendarFilter = false;
    this.availableBtndown = true;
    this.contError = 0;
  }

  public addResponseFileExcel(currentPage:any) {
    const findedFile = this.arrayNumbersPager.some(code => code === currentPage);
    if (!findedFile) {
      this.arrayNumbersPager.push(currentPage);
      this.arrayDownloadFile = this.arrayDownloadFile.concat(this.responseMovements);
    }
  }

  public errorValidateResp(respMsgError: any) {
    if (respMsgError.error.errors[0].status !== codeErrors.code406) {
         this.contError = this.contError + 1;
         this.queryMovementsCompLoading = false;
         this.errorMessageMovements = true;
         this.inputError = respMsgError.error.errors[0].detail;

    } else {
      this.calendarFilter = false;
      this.elementsCheck.disabled = false;
      this.errorMessageMovements = true;
      this.Img = false;
      this.clicReload = false;
      this.clicLater = false;
      this.tittle = false;
      this.noProducts = false;
      this.queryMovementsCompLoading = false;
      this.inputError = respMsgError.error.errors[0].detail;
    }
  }

  public openPopup() {
    if (this.moreDataPager == "Y") {
      const dialogRef = this.dialog.open(PopupInfoComponent, { data: { dataKey: 'movements' }, disableClose: true });
      dialogRef.afterClosed().subscribe(() => {
        this.showHidden = true;
        setTimeout(() => {
          this.showHidden = false;
        }, 3000);
      });
    }
  }


  public downAsXLSX(): void {
    this.openPopup();
    var json: any[] = this.arrayDownloadFile;
    const excelFileName = this.projectData[0].ProjectName + '_' + this.projectData[0].ProjectId;
    const informationGeneral: any = JSON.parse(JSON.stringify(this.projectData[1]));
    let dataMovements: any = JSON.parse(JSON.stringify(json));
    dataMovements = this.changeFieldsData(dataMovements);
    informationGeneral.forEach(item => {
      item.ValorAprobado = parseFloat(item.ValorAprobado);
      item.SaldoTotal = parseFloat(item.SaldoTotal);
      item.ValorAPagarCuota = parseFloat(item.ValorAPagarCuota);
      item.SaldoInteresesCorrientes = parseFloat(item.SaldoInteresesCorrientes);
      item.SaldoMora = parseFloat(item.SaldoMora);
      item.SaldoCapital = parseFloat(item.SaldoCapital);
      item.FechaOportunaDePago = this.dateFormat.transform(item.FechaOportunaDePago);
      item.FechaDeVencimiento = this.dateFormat.transform(item.FechaDeVencimiento);
    });
    this.saveDataFileExcel(excelFileName, informationGeneral, dataMovements);
  }

  public changeFieldsData(dataChange: any) {
    dataChange.forEach(element => {
      element.Fecha = element.dateValue;
      element.Origen = element.origin;
      element.Descripcion = element.description;
      element.NumeroDeCredito = element.obligationId;
      element.ValorTotal = parseFloat(element.totalValue);
      element.IdentificacionDelCliente = element.idNumber;
      element.NombreDelCliente = element.disbursementCustomerName;
      element.ValorCapital = parseFloat(element.capitalValue);
      element.ValorIntereses = parseFloat(element.interestValue);
      element.ValorInteresesMora = parseFloat(element.arrearInterestValue);
      element.NumeroDeCuota = element.paymentNumber;
      delete element.dateValue; delete element.origin; delete element.description;
      delete element.obligationId;
      delete element.totalValue;
      delete element.idNumber;
      delete element.disbursementCustomerName;
      delete element.capitalValue;
      delete element.interestValue;
      delete element.arrearInterestValue;
      delete element.paymentNumber;
    });
    return dataChange;
  }




  async saveDataFileExcel(fileName: any, genInfo: any, movementsDatafile: any) {
    const workbook = new ExcelJS.Workbook();
    const worksheetIg = workbook.addWorksheet("Informacion general");
    const worksheet = workbook.addWorksheet("Movimientos");

    // Escribir títulos y datos en "Informacion general"
    worksheetIg.columns = Object.keys(genInfo[0]).map(key => ({ key, header: key, width: 15 }));
    worksheetIg.addRows(genInfo);

    // Aplicar formato a las celdas de "Informacion general"
    worksheetIg.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        for (let i = 1; i <= 6; i++) {
          row.getCell(i).numFmt = "$#,##0.00";
        }
      }
    });

    // Escribir títulos y datos en "Movimientos"
    worksheet.columns = Object.keys(movementsDatafile[0]).map(key => ({ key, header: key, width: 15 }));
    worksheet.addRows(movementsDatafile);

    // Aplicar formato a las celdas de "Movimientos"
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        [5, 8, 9, 10, 11].forEach(col => {
          row.getCell(col).numFmt = col === 11 ? "#0" : "$#,##0.00";
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveExcelFile(buffer, fileName);
  }


  public saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    let initialDay = (new Date().getDate()).toString();
    if (Number(initialDay) < variables.code10) { initialDay = '0' + initialDay; }
    let currentMounth = (new Date().getMonth() + 1).toString();
    if (Number(currentMounth) < variables.code10) { currentMounth = '0' + currentMounth; }
    const currentYear = (new Date().getFullYear()).toString();
    let currentHr = (new Date().getHours()).toString();
    if (Number(currentHr) < variables.code10) { currentHr = '0' + currentHr; }
    let currentMins = (new Date().getMinutes()).toString();
    if (Number(currentMins) < variables.code10) { currentMins = '0' + currentMins; }
    let currentSeg = (new Date().getSeconds().toString());
    if (Number(currentSeg) < variables.code10) { currentSeg = '0' + currentSeg; }
    FileSaver.saveAs(data, fileName + '_' + initialDay + currentMounth +
      currentYear + '_' + currentHr + currentMins + currentSeg + EXCEL_EXTENSION);
  }
}
