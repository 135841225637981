import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueryService } from '../../services/query.service';
import { SetDataApp } from '../../../store/actions/shareparam.action';
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';

import { FormatDatePipe } from '../../../shared/pipes/format/format-date.pipe';
import { codeErrors, numberRepeatLines, variables } from '../../../shared/constants';
import { MortgageBalance } from '../../../shared/models/queryBalanceMortgage';
import { ComponentStatesService } from '../../services/queryMortgageBalanceService';
import { PaymentsService } from '../../../payments/services/payments.service';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-query-detail',
  templateUrl: './query-detail.component.html',
  styleUrls: ['./query-detail.component.scss'],
  providers: [Store, QueryService, FormatDatePipe]
})

export class QueryDetailComponent implements OnInit {

  public id: any;
  @Input() tryCount: number = 0
  @Input() details: any;
  @Input() row: any;
  @Output() empty = new EventEmitter();
  @Output() detailProject = new EventEmitter();
  @Output() dataReponse = new EventEmitter();
  @Input() project;
  numberRepeatLines = numberRepeatLines;
  endAnimate = true;
  mostrarnotificacion = false;
  array: Array<any> = [];
  arrayRespQueryBalance: MortgageBalance[] = new Array();
  mortgageBalance: MortgageBalance;

  @Output() respPager = new EventEmitter();
  @Output() dataCard = new EventEmitter;
  queryloadMoreData = false;
  lastId: any = '';
  showBtn = false;
  cont = 0;
  arrayRow: any;

  mostrarBotonPagarFFlag: boolean = false;

  constructor(
    private router: Router,
    private store: Store<{ shareparams: any }>,
    private componentStatesService: ComponentStatesService,
    public queryService: QueryService, private datePipe: FormatDatePipe,
    private paymentService: PaymentsService) { }

  ngOnInit() {
    this.getMorgageBalance();
  }


  public getMorgageBalance() {
    let positionCard = null;
    this.store.select('shareparams').subscribe(async state => {
      if (state) {
        this.queryService.pcsQueryMortgageLoanBalanceRq(state.dataapp, state.id, state.type, this.lastId)
          .subscribe(response => {
            const resp = response.data.attributes.param.accountDetails;
            this.validatePager(response.data.attributes.param.pendingInfo);
            if (this.cont < variables.code2) {
              this.array[this.details] = resp;
              positionCard = this.details;
            } else {
              this.queryloadMoreData = false;
              resp.forEach((element: any) => {
                this.array[this.arrayRow].push(element);
              });
              positionCard = this.arrayRow;
            }
            this.validateRespService(resp, positionCard, state.id, state.type);
            this.endAnimate = false;
          }, error => {
            if (error.status === codeErrors.code406 || error.status === codeErrors.code400) {
              const msgempty: any = { 'position': this.details, 'message': error };
              this.empty.emit(msgempty);
            } else if(error.status === codeErrors.code500 && error.error.errors[0].code === 2 && this.tryCount < 3) {
              const msgempty: any = { 'position': this.details, 'message': error };
              this.empty.emit(msgempty);
            }else {
              const actionerror = new SetDataApp(error);
              this.store.dispatch(actionerror);
              this.router.navigate(['notificacion']);
            }
          });
      }
    }).unsubscribe();
  }

  public validatePager(serviceResp: any) {
    this.cont = this.cont + 1;
    if (serviceResp == 'Y') {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }
    this.paymentService.saveRes(serviceResp);
    this.respPager.emit(serviceResp);
  }

  public queryMoreData(row: any) {
    this.arrayRow = row;
    this.dataCard.emit(1);
    const fileDataFinded = this.arrayRespQueryBalance.find(code => code.cardNumber === row);
    this.lastId = fileDataFinded.lastID;
    this.queryloadMoreData = true;
    this.getMorgageBalance();

  }

  public validateRespService(resp, cardNum, idCard, typeDocCard) {
    const currentValue = this.componentStatesService.objResQueryMortgage.getValue();
    if (currentValue) {
      this.arrayRespQueryBalance = currentValue;
    }
    if (resp.length < variables.code3) {
      this.mortgageBalance = new MortgageBalance(resp, cardNum, idCard, typeDocCard, "");
    } else {
      this.mortgageBalance = new MortgageBalance(resp, cardNum, idCard, typeDocCard, resp[variables.code2].projectId);
    }
    const findedFile = this.arrayRespQueryBalance.some(code => code.cardNumber === cardNum);
    if (!findedFile) {
      this.arrayRespQueryBalance.push(this.mortgageBalance);
      this.componentStatesService.setObjResQueryMortgage(this.arrayRespQueryBalance);
    } else if (this.mortgageBalance.lastID != "") {
      this.arrayRespQueryBalance[cardNum].lastID = this.mortgageBalance?.lastID;
      this.componentStatesService.setObjResQueryMortgage(this.arrayRespQueryBalance);
    }
  }

  public seeDetails(dataDetailProject: any, numCard: any) {
    const fileDataFinded = this.arrayRespQueryBalance.find(code => code.cardNumber === numCard);
    this.detailProject.emit({ "dataSelected": fileDataFinded, "projectId": dataDetailProject });
  }

  // public exportAsXLSX(json: any[], excelFileName: string): void {
  //   let data: any = JSON.parse(JSON.stringify(json));
  //   data = this.orderDataExcel(data);
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { 'Creditos Constructor': worksheet },
  //     SheetNames: ['Creditos Constructor']
  //   };
  //   for (let index = 0; index <= data.length; index++) {
  //     workbook.Sheets['Creditos Constructor']['C' + (index + 1)].z = '$#,##0.00';
  //     workbook.Sheets['Creditos Constructor']['D' + (index + 1)].z = '$#,##0.00';
  //     workbook.Sheets['Creditos Constructor']['E' + (index + 1)].z = '$#,##0.00';
  //     workbook.Sheets['Creditos Constructor']['F' + (index + 1)].z = '$#,##0.00';
  //     workbook.Sheets['Creditos Constructor']['G' + (index + 1)].z = '$#,##0.00';
  //     workbook.Sheets['Creditos Constructor']['H' + (index + 1)].z = '$#,##0.00';
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['C' + (index + 1)]);
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['D' + (index + 1)]);
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['E' + (index + 1)]);
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['F' + (index + 1)]);
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['G' + (index + 1)]);
  //     XLSX.utils.format_cell(workbook.Sheets['Creditos Constructor']['H' + (index + 1)]);
  //   }
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   this.saveAsExcelFile(excelBuffer, excelFileName);
  // }
  public async exportAsXLSX(json: any[], excelFileName: string): Promise<void> {
    let data: any = JSON.parse(JSON.stringify(json));
    data = this.orderDataExcel(data);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Creditos Constructor');

    // Escribir títulos y datos en "Creditos Constructor"
    const columns = Object.keys(data[0]).map(key => ({ key, header: key, width: 15 }));
    worksheet.columns = columns;
    worksheet.addRows(data);

    // Aplicar formato a las celdas de "Creditos Constructor"
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        [3, 4, 5, 6, 7, 8].forEach(col => {
          row.getCell(col).numFmt = '$#,##0.00';
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, excelFileName);
  }
  public orderDataExcel(data) {
    data.forEach(element => {
      element.NombreDelProyecto = element.projectName;
      element.NoProyecto = element.projectId;
      element.ValorAprobado = parseFloat(element.approvedAmount);
      element.SaldoTotalCredito = parseFloat(element.pendingBalance.amount);
      element.ValorAPagar = parseFloat(element.quarterlyInterest.amount);
      element.SaldoCapital = parseFloat(element.capitalBalance);
      element.SaldoInteresesCorrientes = parseFloat(element.currentInterestBalance);
      element.SaldoMora = parseFloat(element.arrearsInterestBalance);
      element.FechaDePago = this.datePipe.transform(element.nextPaymentDate);
      element.FechaVencimiento = this.datePipe.transform(element.dueDate);
      delete element.ownerName;
      delete element.projectName;
      delete element.projectId;
      delete element.pendingBalance;
      delete element.quarterlyInterest;
      delete element.approvedAmount;
      delete element.nextPaymentDate;
      delete element.dueDate;
      delete element.capitalBalance;
      delete element.currentInterestBalance;
      delete element.arrearsInterestBalance;
    });
    return data;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    let currentDay = (new Date().getDate()).toString();
    if (Number(currentDay) < variables.code10) { currentDay = '0' + currentDay; }
    let currentMounth = (new Date().getMonth() + 1).toString();
    if (Number(currentMounth) < variables.code10) { currentMounth = '0' + currentMounth; }
    const currentYear = (new Date().getFullYear()).toString();
    let currentHr = (new Date().getHours()).toString();
    if (Number(currentHr) < variables.code10) { currentHr = '0' + currentHr; }
    let currentMins = (new Date().getMinutes()).toString();
    if (Number(currentMins) < variables.code10) { currentMins = '0' + currentMins; }
    let currentSeg = (new Date().getSeconds().toString());
    if (Number(currentSeg) < variables.code10) { currentSeg = '0' + currentSeg; }
    FileSaver.saveAs(data, fileName + '_' + currentDay + currentMounth +
      currentYear + '_' + currentHr + currentMins + currentSeg + EXCEL_EXTENSION);
  }
}
