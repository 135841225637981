import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { APP_BASE_HREF, CurrencyPipe, registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { GenericModule } from './generic-components/generic.module';
import { GenericRoutingModule } from './generic-components/generic-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FFlagsContextProviderService, FFlagsOptions, NgxFFlagsModule, FFlagsContext } from '@bancolombia/ngx-fflags';

import { AppReducers } from './app.reducers';
import { QueryModule } from './query/query.module';
import { QueryEffects } from './store/effects/query.effects';
import { PaymentsModule } from './payments/payments.module';
import { AppMaterialModule } from './shared/app-material.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { environment } from '../environments/environment';

import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcIllustrationModule } from '@bancolombia/design-system-web/bc-illustration';
import { BcPictogramModule } from '@bancolombia/design-system-web/bc-pictogram';
import localeEsCo from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCo, 'es-CO');

const fFlagsOptions: FFlagsOptions = {
  api: environment.PATH_FFLAG,
  appName: 'AW0360004_PortalConstructor',
  apiKey: environment.API_KEY_FFLAG,
  cacheTime: 0, // seconds
  debug: true, // print debug lines to console, disabled by default
};

const context: FFlagsContext = {
  userId: '',
  properties: {},
  features: ["mostrarBotonPagar"]
};

const contextProvider = new FFlagsContextProviderService(context);


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        GenericRoutingModule,
        GenericModule,
        StoreModule.forRoot(AppReducers),
        EffectsModule.forRoot([QueryEffects]),
        HttpClientModule,
        RouterModule,
        FormsModule,
        AppMaterialModule,
        ReactiveFormsModule,
        QueryModule,
        BrowserAnimationsModule,
        PaymentsModule,
        PipesModule,
        NgxFFlagsModule.initializeApp(fFlagsOptions, contextProvider),
        BcIconModule.forRoot({
            path: 'https://library-sdb.apps.bancolombia.com/bds/6.40.2'
            // path: 'https://galatea-dev.apps.ambientesbc.com/2.0.6'
        }),
        BcIllustrationModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.40.2' }),
        BcPictogramModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.40.2' })
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/web' },
        CurrencyPipe,
        {
            provide: FFlagsContextProviderService,
            useValue: contextProvider
        },
        {
          provide: LOCALE_ID,
          useValue: 'es-CO',
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
